import { globalTheme } from 'srs.sharedcomponents/lib/esm/styles/config'

const themeColor = '#B0B1B4'
export const customTheme = {
  ...globalTheme,
  colors: {
    ...globalTheme.colors,
    primary: themeColor,
  },
}
